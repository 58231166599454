import { Controller } from "stimulus"

export default class PrintFileController extends Controller {
  static targets = ['content', 'missing']

  initialize() {
    this.checkThumbnail()
  }

  checkThumbnail() {
    setTimeout(() => {
      if (this.hasMissingTarget) {
        this.loadThumbnail()
      } else {
        window.thumbnails()
      }
    }, Math.random() + 1000)
  }

  loadThumbnail () {
    let request = new XMLHttpRequest();
    let url = this.data.get('url')
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.contentTarget.innerHTML = html
        // check again
        this.checkThumbnail()
      })
  }
}

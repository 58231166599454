import { Controller } from "stimulus"

export default class FilterController extends Controller {
  static targets = ['searchable', 'input']

  filter() {
    let rex = new RegExp(this.inputTarget.value, 'i')
    let lines = this.searchableTarget.querySelectorAll('tr')

    lines.forEach(function(line) {
      if (rex.test($(line).text())) {
        $(line).show()
      } else {
        $(line).hide()
      }
    })
  }
}

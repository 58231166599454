import { Controller } from "stimulus"

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import './../select2-bootstrap.min.css'

export default class Select2Controller extends Controller {
  static targets = ['select']
  static values = { autocompletePath: String }

  initialize() {
    this.initAutocompleteSelect2()
    this.overrideSelect2ChangeEvent()
    this.addTurboDataAction()
  }

  // destroy Select2 before Turbo caches page, preventing multiple initialization
  addTurboDataAction() {
    const current = $(this.selectTarget).attr('data-action') || ''
    const added = `${current} turbo:before-cache@window->select2#remove`.trim()

    $(this.selectTarget).attr('data-action', added)
  }

  remove() {
    if ($(this.selectTarget).hasClass('select2-hidden-accessible')) {
      $(this.selectTarget).select2('destroy')
    }
  }

  // translate `select2:select` event to regular `change` event
  overrideSelect2ChangeEvent() {
    $(this.selectTarget).on('select2:select', () => {
      const event = new Event('change', { bubbles: true })
      this.selectTarget.dispatchEvent(event)
    })
  }

  initAutocompleteSelect2() {
    let selectOptions = { theme: 'bootstrap4', matcher: this.matchCustom }
    if (this.hasAutocompletePathValue) {
      selectOptions['ajax'] = this.autocompleteOptions()
    }

    $(this.selectTarget).select2(selectOptions);
  }

  autocompleteOptions() {
    return {
      url: this.autocompletePathValue,
      dataType: 'json',
      delay: 0.1,
      data: (params) => {
        return {
          q: params.term,
          page: params.page,
        }
      },
      processResults: (data, params) => {
        params.page = params.page || 1
        return {
            results: data,
            pagination: { more: (params.page * 30) < data.total_count }
        }
      },
      cache: true,
    }
  }

  // https://select2.org/searching
  matchCustom(params, data) {
    // `params.term` should be the term that is used for searching
    // `data.text` is the text that is displayed for the data object

    // If there are no search terms, return all of the data
    if ($.trim(params.term) === '') {
      return data;
    }

    // Do not display the item if there is no 'text' property
    if (typeof data.text === 'undefined') {
      return null;
    }

    const textToSearch = data.text.replaceAll('-', '') + ' ' + data.text
    if (textToSearch.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
      return data;
    }

    // Return `null` if the term should not be displayed
    return null;
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['info', 'id', 'reset-button']

  initialize() {
    window.addEventListener("message", (event) => {
      if(event.data.dpdWidget) {
        this.infoTarget.value = this.generatePickupPointInfo(event.data.dpdWidget)
        this.idTarget.value = event.data.dpdWidget.id
        this.infoTarget.classList.add("selected-pickup-point")

        $("#dpdpickup").modal("hide")
      }
    }, false);
  }

  generatePickupPointInfo(dpdWidget) {
    const address = dpdWidget.location.address
    return `${dpdWidget.name}, ${address.street}, ${address.city}`
  }

  cleanSelected() {
    this.infoTarget.value = null
    this.idTarget.value = null
    this.infoTarget.classList.remove("selected-pickup-point")
  }

  openModal(){
    $("#dpdpickup").modal("toggle")
  }
}

import { Controller } from "stimulus"

window.check_order = function() {
  let id = $("#order_id").attr('data-id');
  $.get("/orders/" + id + "/check_formats.json", function(data) {
    if (data.check === true) {
      $("#format_alert").removeClass('hidden');
    } else {
      $("#format_alert").addClass('hidden');
    }
  });
}

window.manual_edit = function() {
  $(".manual-edit-file").change(function() {
    $.post("/print_files/" + ($(this).attr('data-id')) + "/manual_edit");
  });
}

window.thumbnails = function() {
  $("#thumbnail").remove();
  $(".thumbnail").click(function(event) {
    let large = $(this).attr("data-large");
    var el = $("<div id='thumbnail' />");
    el.attr("style", "background: #FFF; position: fixed; top: 10px; left: " + (event.pageX - 30) + "px; height: 90%;");
    let image = $("<img src='" + large + "' />");
    el.append(image);

    el.mouseout(function() {
      $(this).remove();
    });

    el.click(function() {
      $(this).remove();
    });

    $("body").append(el);
  });
}

export default class EditableFilesController extends Controller {
  connect() {
    window.manual_edit();
    window.thumbnails();

    if (!$('#file_upload').hasClass('initialized')) {
      $('#file_upload').addClass('initialized');
      $("#file_upload").fileupload({
        dataType: 'json',
        recalculateProgress: true,
        submit: (function(_this) {
          return function(e, data) {
            var ext, file, re, _i, _len, _ref;
            $("#uploaded_file").attr("disabled", "disabled");
            $("#upload_form input[type='submit']").attr("disabled", true);

            re = /(?:\.([^.]+))?$/;
            _ref = data.originalFiles;
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
              file = _ref[_i];
              ext = re.exec(file.name)[1].toLowerCase();
              if (ext === "zip" || ext === "rar" || ext === "7z") {
                $("#uploaded_file").removeAttr("disabled");
                return false;
              }
            }
          };
        })(this),
        progressall: function(e, data) {
          var progress;
          progress = data.loaded / data.total * 100;
          $("#file-progress .progress-bar").attr("style", "width: " + progress + "%");
          return $("#file-progress .progress-bar").text((Math.round(progress)) + "%");
        },
        done: function(e, data) {
          $("#upload_form input[type='submit']").attr("disabled", false);
          setTimeout(function() {
            $("#file-progress .progress-bar").removeAttr("style");
            return $("#file-progress .progress-bar").text("");
          }, 3000);
          $("#uploaded_file").removeAttr("disabled");
          $("#files tbody").append(data.result.html_string);
          $("#files").removeClass('hidden');
          $(".submit_button").removeClass('hidden');
          $(".delete-file").bind("ajax:success", function() {
            return $(this).closest('.file-row').fadeOut(function() {
              $(this).remove();
              window.check_order();
            });
          });
          window.thumbnails();
          window.manual_edit();
          window.check_order();
        }
      });
    }

    if (!$('#uploaded_file').hasClass('initialized')) {
      $('#uploaded_file').addClass('initialized');

      $("#uploaded_file").fileupload({
        recalculateProgress: true,
        submit: (function(_this) {
          return function(e, data) {
            var ext, file, re, _i, _len, _ref;
            $("#fileupload").attr("disabled", "disabled");
            $("#submit_archive").attr("disabled", "disabled");
            $("#upload_form input[type='submit']").attr("disabled", true);
            re = /(?:\.([^.]+))?$/;
            _ref = data.originalFiles;
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
              file = _ref[_i];
              ext = re.exec(file.name)[1].toLowerCase();
              if (ext !== "zip" && ext !== "rar" && ext !== "7z") {
                $("#fileupload").removeAttr("disabled");
                return false;
              }
            }
          };
        })(this),
        progressall: function(e, data) {
          var progress;
          progress = data.loaded / data.total * 100;
          $("#archive-progress .progress-bar").attr("style", "width: " + progress + "%");
          return $("#archive-progress .progress-bar").text((Math.round(progress)) + "%");
        },
        done: function(e, data) {
          $("#upload_form input[type='submit']").attr("disabled", false);
        }
      });

      $('#uploaded_file').addClass('initialized');
    }

    // $(document).on('turbo:before-cache', function() {
    //   if ($('#uploaded_file').hasClass('initialized')) {
    //     $("#uploaded_file").fileupload('destroy')
    //     $("#uploaded_file").removeClass('initialized')
    //   }
    //   if ($('#file_upload').hasClass('initialized')){
    //     console.log('disconnected')
    //     $("#file_upload").fileupload('destroy')
    //     $("#file_upload").removeClass('initialized')
    //   }
    // })
  }

  cleanup() {
    $("#uploaded_file").fileupload('destroy')
    $("#file_upload").fileupload('destroy')
  }
}

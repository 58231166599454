import { Controller } from "stimulus"

export default class OrderStatusController extends Controller {
  static targets = ['select']
  static values = { url: String }

  changeStatus() {
    var newStatus = this.selectTarget.value

    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: "status=" + newStatus,
    })
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    setTimeout(function() {
      if (location.pathname == '/' || location.pathname == '/orders') {
        Turbo.visit(location)
      }
    }, 120 * 1000)
  }
}

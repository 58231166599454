import { Controller } from "stimulus"

export default class FullSummernoteController extends Controller {
  static targets = ['input', 'form']

  initialize() {
    $(this.inputTarget).summernote({
      height: 300,
      lang: 'cs-CZ',
      toolbar: [
        ['style', ['style']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['link', ['link']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['hr', ['hr']]
      ],
      styleTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    })
  }

  disconnect() {
    $(this.inputTarget).summernote('destroy');
  }
}

import { Controller } from "stimulus"

export default class DpdOrderValidationController extends Controller {
  validateForm(e) {
    // first run standard HTML5 validations
    if (!this.element.reportValidity())
      return

    const formData = new FormData(this.element)
    let cashOnDelivery = formData.get('dpd_order[cash_on_delivery]')
    let variableSymbol = formData.get('dpd_order[variable_symbol]')

    // not one or both cash on delivery and variable symbol must be filled
    if ((cashOnDelivery && !variableSymbol) || (!cashOnDelivery && variableSymbol)) {
      e.preventDefault()
      alert("Musí být vyplněna částka dobírky i variabilní symbol.")
      return
    }

    this.element.dataset.submitted = true
  }
}

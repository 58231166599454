import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  initialize() {
    // TODO: remove after tested. This should no longer be needed with FormStateController
    this.addTurboDataAction()
    this.submitButtonTarget.style.display = 'none'
  }

  addTurboDataAction() {
    const action = 'turbo:before-cache@window->turbo-submit#setSelected'

    this.element.dataset.action ||= ''
    if (!this.element.dataset.action.includes(action)) {
      this.element.dataset.action += ` ${action}`
    }
  }

  submit(e) {
    if (e.target.type != 'text') {
      this.element.querySelector('form').requestSubmit(this.submitButtonTarget)
    } else {
      let valueLength = e.target.value.length
      if (valueLength == 0 || valueLength >= 3) {
        this.element.querySelector('form').requestSubmit(this.submitButtonTarget)
      }
    }
  }

  // Keeps filter settings for select when going back to the previous page
  setSelected() {
    const selectFields = this.element.querySelectorAll('select')

    selectFields.forEach(function(selectField) {
      // Selected non blank option
      // Adds 'selected' attribute to the option so it can be remembered by Turbo
      if (selectField.value) {
        selectField.options[selectField.selectedIndex].setAttribute('selected', '')
      }

      // Selected blank option
      // Removes 'selected' attribute previously added, otherwise it rememberes non blank selected option
      else {
        const selectedOptions = selectField.querySelectorAll('option[selected]')

        selectedOptions.forEach((option) => {
          option.removeAttribute('selected')
        })
      }
    })
  }
}

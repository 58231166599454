import { Controller } from "stimulus"
import $ from 'jquery'

export default class SummernoteController extends Controller {
  static targets = ['input', 'form']

  initialize() {
    $(this.inputTarget).summernote({
      height: 100,
      lang: 'cs-CZ',
      toolbar: [],
      callbacks: {
        onBlur: () => {
          const event = new Event('blur', { bubbles: true })
          this.inputTarget.dispatchEvent(event)
        }
      }
    })
  }

  submit() {
    console.log('submit')
     $(this.element).submit()
  }

  disconnect() {
    $(this.inputTarget).summernote('destroy');
  }
}
